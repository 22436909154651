import { apiGetSetting, apiPostSetting } from '@/api/game';
import { SETTINGS } from '@/config/storage';
import { ACCOUNT } from '@/config/session';
import * as SportLib from '@/utils/SportLib';
import { Message } from 'element-ui';

const defaultSetting = Object.freeze({
  // 賠率是否含本金
  includePrincipal: false,
  // 盤口賽事排序方式 0: 熱門 1: 時間
  tableSort: 0,
  // 自動接收最佳賠率
  acceptBetter: false,
  // 下注確認信息
  showBetConfirm: true,
  // 選擇兩關以上(含兩關) 是否自動顯示為過關面板
  autoSwitchToStrayMode: false,
  // 默認金額
  defaultAmount: {
    // type: 0: 關閉 | 1: 最後投注, 2: 自訂金額
    type: 2,
    amount: 100,
  },
  // 默認過關投注
  defaultStrayAmount: {
    // type: 0: 關閉 | 1: 最後投注, 2: 自訂金額
    type: 2,
    amount: 100,
  },
  // 偏好籌碼 (最多6個)
  preferChips: SportLib.chipsData.slice(0, 4).map((chip) => chip.value),
  // 收藏夾
  favorites: [],
  // 聯盟
  leagues: {},
  i18nLocale: null,
  theme: null,
});

export default {
  namespaced: true,
  state: {
    UserSetting: {},
  },
  getters: {},
  mutations: {},
  actions: {
    init({ rootState, state, commit, dispatch }) {
      let settings = {};
      const settingsData = localStorage.getItem(SETTINGS);
      if (settingsData) {
        try {
          settings = JSON.parse(settingsData);
        } catch {
          settings = {};
        }
      }

      const account = sessionStorage.getItem(ACCOUNT);
      state.UserSetting = JSON.parse(JSON.stringify(defaultSetting));
      const { UserSetting } = state;
      if (settings?.[account]) {
        Object.assign(UserSetting, settings[account]);
      }

      const savetime = localStorage.getItem('setting_time');
      let diff = null;

      if (savetime) {
        try {
          const now = new Date();
          const old = new Date(savetime);
          diff = now - old;
        } catch (error) {}
      }
      return apiGetSetting(diff)
        .then((response) => {
          if (response) {
            const { UserSetting } = state;
            const setting = JSON.parse(response);
            Object.keys(setting).forEach((key) => {
              if (Object.prototype.hasOwnProperty.call(UserSetting, key)) {
                UserSetting[key] = setting[key];
              }
            });
          }
        })
        .catch((error) => {
          console.warn(error);
        })
        .finally(() => {
          localStorage.setItem('setting_time', new Date().toString());

          const { UserSetting } = state;

          const allowed = SportLib.chipsData.map((chip) => chip.value);
          UserSetting.preferChips = UserSetting.preferChips.filter((chip) =>
            allowed.includes(chip)
          );
          if (UserSetting.preferChips.length === 0) {
            Object.assign(UserSetting.preferChips, defaultSetting.preferChips);
          }

          if (UserSetting.i18nLocale === null) {
            UserSetting.i18nLocale = rootState.i18nLocale;
          }
          if (UserSetting.theme === null) {
            UserSetting.theme = rootState.darkTheme ? 'dark' : '';
          }

          dispatch('saveLocal');

          commit('Game/setQuickBetAmount', UserSetting.defaultAmount.amount, { root: true });

          if (UserSetting.i18nLocale !== rootState.i18nLocale && !rootState.siteLabel) {
            commit('setI18nLocale', UserSetting.i18nLocale, { root: true });
          }
          if (UserSetting.theme === 'dark') {
            commit('setDarkTheme', true, { root: true });
          } else {
            commit('setDarkTheme', false, { root: true });
          }
        });
    },
    saveLocal({ state }) {
      let settings = {};
      const settingsData = localStorage.getItem(SETTINGS);
      if (settingsData) {
        try {
          settings = JSON.parse(settingsData);
        } catch {
          settings = {};
        }
      }

      const account = sessionStorage.getItem(ACCOUNT);
      settings[account] = state.UserSetting;

      try {
        localStorage.setItem(SETTINGS, JSON.stringify(settings));
      } catch {}
    },
    save({ rootState, state, dispatch }) {
      const { UserSetting } = state;
      UserSetting.i18nLocale = rootState.i18nLocale;
      UserSetting.theme = rootState.darkTheme ? 'dark' : '';

      dispatch('saveLocal');

      const setting = {};
      [
        'includePrincipal',
        'tableSort',
        'acceptBetter',
        'showBetConfirm',
        'autoSwitchToStrayMode',
        'defaultAmount',
        'defaultStrayAmount',
        'preferChips',
        'i18nLocale',
        'theme',
      ].forEach((key) => {
        if (key in UserSetting) {
          setting[key] = UserSetting[key];
        }
      });

      return apiPostSetting(setting);
    },
    setSettings({ state, commit, dispatch }, val) {
      Object.assign(state.UserSetting, val);
      dispatch('save');
      commit('Game/setQuickBetAmount', state.UserSetting.defaultAmount.amount, { root: true });
    },
    setIncludePrincipal({ state, dispatch }, val) {
      state.UserSetting.includePrincipal = val;
      dispatch('save');
    },
    setTableSort({ state, dispatch }, val) {
      state.UserSetting.tableSort = val;
      dispatch('save');
    },
    setAcceptBetter({ state, dispatch }, val) {
      state.UserSetting.acceptBetter = val;
      dispatch('save');
    },
    addFavorites({ state, dispatch }, val) {
      const { UserSetting } = state;
      const findIndex = UserSetting.favorites.findIndex((item) => item === val);
      if (findIndex === -1) {
        UserSetting.favorites.push(val);
        Message({
          type: 'success',
          message: '收藏成功!',
        });
      } else {
        UserSetting.favorites.splice(findIndex, 1);
        Message({
          type: 'warning',
          message: '取消收藏!',
        });
      }
      dispatch('saveLocal');
    },
    setFavorites({ state, dispatch }, val) {
      state.UserSetting.favorites = val;
      dispatch('saveLocal');
    },
    setLeagues({ rootState, state, commit, dispatch }, { cat, leagues }) {
      const { UserSetting } = state;
      const t = rootState.Game.selectGameType;
      const c = UserSetting.leagues[cat];
      const list = c ? c[t] : null;
      const set = list ? new Set(list) : new Set();
      leagues.forEach((league) => {
        if (league.isSelect) {
          if (set.has(league.LeagueID)) set.delete(league.LeagueID);
        } else {
          set.add(league.LeagueID);
        }
      });
      if (!UserSetting.leagues[cat]) UserSetting.leagues[cat] = {};
      UserSetting.leagues[cat][t] = Array.from(set);
      dispatch('saveLocal');
      commit('Game/setSelectLeagueIDs2', UserSetting.leagues[cat][t], { root: true });
    },
    clearLeagues({ state, commit, dispatch }) {
      state.UserSetting.leagues = {};
      dispatch('saveLocal');
      commit('Game/setSelectLeagueIDs2', [], { root: true });
    },
  },
};
